import React from "react";
import { Flex, Text, Box, Icon } from "@chakra-ui/react";
import LogoHeveaBranco from '../../../assets/img/dashboards/logo_hevea_branco.png';
import { HSeparator } from "components/separator/Separator";
import * as ActionTypes from '../../../constants/ActionTypes'
import { MdBrightness1 } from "react-icons/md";

export function SidebarBrand() {

    // TODO IMAGEM LOGO MENU
    const nomeUser = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');
    const nomeUa = localStorage.getItem('@HE-nomeUa');
    const nomeSafra = localStorage.getItem('@HE-nomeSafra');
    const perfil = localStorage.getItem('@HE-descPerfilUsuarioLogado');
    const sangriaAtiva = localStorage.getItem('@HE-sangriaAtivaAsync');
    const versao = localStorage.getItem('@HE-currentVersaoHeveaWeb');
    const colorAmbiente = ActionTypes.ENVIRONMTE == 'Des' ? 'yellow' : 'lime';

    return (
        <Box position={'relative'}>
            <Flex align='center' direction='column' mt={'5px'}>
                <img src={LogoHeveaBranco} alt="LogoHevea" style={{ width: '200px', height: '70px' }} />

                <Flex px={'20px'} align='center' >
                    <Text noOfLines={1} color={'rgba(255, 255, 255, 0.9)'} fontSize='18px' lineHeight={'100%'} fontWeight='700'>{nomeUser}</Text>
                    <Text color={'rgba(255, 153, 0, 0.9)'} fontSize='13px' lineHeight={'100%'} fontWeight='100' ml={'5px'}>{perfil}</Text>
                </Flex>

                <Text px={'20px'} noOfLines={1} color={'rgba(255, 255, 255, 0.9)'} fontSize='13px' lineHeight={'100%'} mt={'5px'} fontWeight='100'>{nomeUa}</Text>
                <Text px={'20px'} noOfLines={1} color={'rgba(255, 255, 255, 0.9)'} fontSize='13px' lineHeight={'100%'} fontWeight='100'>{nomeSafra}</Text>

                {/* {sangriaAtiva == 'false' &&
                    <Box px={'15px'} py={'3px'} mt={'10px'} bg={'#e60000'} borderRadius={'8px'} >
                        <Text color={'white'} fontSize={'13px'} fontWeight={'light'} lineHeight={'100%'} align={'center'}>{'Safra Finalizada'}</Text>
                    </Box>
                } */}

                {sangriaAtiva == 'false' ? (
                    <Box px={'15px'} py={'3px'} mt={'10px'} bg={'#e60000'} borderRadius={'8px'} >
                        <Text color={'white'} fontSize={'13px'} fontWeight={'light'} lineHeight={'100%'} align={'center'}>{'Safra Finalizada'}</Text>
                    </Box>
                ) : (
                    <Box px={'15px'} py={'3px'} mt={'10px'} bg={'#009933'} borderRadius={'8px'} >
                        <Text color={'white'} fontSize={'13px'} fontWeight={'light'} lineHeight={'100%'} align={'center'}>{'Safra Vigente'}</Text>
                    </Box>
                )}
                <HSeparator mb='5px' mt='10px' />
            </Flex>
            <Box position={'absolute'} top={'0px'} right={'35px'}>
                <Flex pt={'5px'} align='center' justify={'center'}>
                    <Icon as={MdBrightness1} color={colorAmbiente} w={3} h={3} mr={'1px'} />
                    <Text color={'rgba(255, 255, 255, 0.9)'} fontSize='13px' fontWeight='100' ml={'5px'}>{`V ${versao}`}</Text>
                </Flex>
            </Box>
        </Box>
    );
}

export default SidebarBrand;
