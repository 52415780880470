import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Select,
    Wrap,
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
import { LIST_UFS } from 'model/MockData'
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export function FormGerentes() {

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    const history = useHistory();
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [isValidNome, setIsValidNome] = useState(false);
    const [inputNome, setInputNome] = useState('');
    const [inputEndereco, setInputEndereco] = useState('');
    const [idEnderecoP, setIdEnderecoP] = useState(null);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [isValidCelular, setIsValidCelular] = useState(false);
    const [inputCelular, setInputCelular] = useState('');
    const [isValidUf, setIsValidUf] = useState(false);
    const [ufSelecionado, setUfSelecionado] = useState('');
    const [isValidMunicipio, setIsValidMunicipio] = useState(false);
    const [inputTelefoneFixo, setInputTelefoneFixo] = useState('');
    const [listMunicipio, setListMunicipio] = useState([]);
    const [municipioSelecionado, setMunicipioSelecionado] = useState('');
    const [isDisabledMunicipio, setIsDisabledMunicipio] = useState(true);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [operacao, setOperacao] = useState(null);
    const [idUsuarioGerenteP, setIdUsuarioGerenteP] = useState(null);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [idUaP, setIdUaP] = useState('');
    const [idSafraP, setIdSafraP] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const dadosGerente = localStorage.getItem('@HE-dadosGerenteParaEdicao');
            if (dadosGerente !== null) {
                const param = JSON.parse(dadosGerente);

                setNomeFazenda(param.nomeFazenda);
                setIdUaP(param.idUa);
                setIdSafraP(param.idSafra);
                const insertOuUpdate = param.insertOuUpdate;
                /*
                ╔══════════════════════════════════════════════════════════════════╗
                    INSERT OU UPDATE
                ╚══════════════════════════════════════════════════════════════════╝ */
                if (insertOuUpdate == ActionTypes.OPERACAO_UPDATE) {

                    setOperacao(ActionTypes.OPERACAO_UPDATE);
                    const idUser = param.idUsuario;
                    setIdUsuarioGerenteP(idUser);

                    const url = `api/usuario/retornaUsuariosTipoGerente?idTipoUsuario=2&idUa=${param.idUa}&idUsuario=${param.idUsuario}`;
                    const response = await Api.getRequest(url);
                    if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                        const res = await response.respostaRequisicao.data;

                        if (res.length > 0) {

                            const objRes = res[0];

                            setInputNome(objRes.nome !== null && objRes.nome !== '' ? objRes.nome : '');
                            setInputTelefoneFixo(objRes.telefoneFixo !== null && objRes.telefoneFixo !== '' ? mascaraTelefone(objRes.telefoneFixo) : '');
                            setInputCelular(objRes.numeroCelular !== null && objRes.numeroCelular !== '' ? mascaraTelefone(objRes.numeroCelular) : '');
                            setInputEmail(objRes.emailPessoal !== null && objRes.emailPessoal !== '' ? objRes.emailPessoal : '');

                            const obj = objRes.objEndereco;
                            console.log('');
                            const idMu = obj.idMunicipio !== null && obj.idMunicipio !== '' ? `${obj.idMunicipio}` : null;
                            setMunicipioSelecionado(idMu);
                            setIdEnderecoP(obj.idEndereco);
                            setInputEndereco(obj.endereco !== null && obj.endereco !== '' ? obj.endereco : '');

                            carregarUf(obj.idUf);
                            setTelaCarregada(true);
                        }
                    }
                } else {
                    setOperacao(ActionTypes.OPERACAO_INSERT);
                    setTelaCarregada(true);
                }
            } else {
                setTelaCarregada(true);
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const changeUf = async (e) => {
        const uf = e.target.value;
        carregarUf(uf);
    }

    const carregarUf = async (e) => {
        const uf = e;
        setUfSelecionado(`${uf}`);

        if(uf != ''){
            const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);
    
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
    
                if (res.length > 0) {
                    let arr = res.map((item, index) => {
                        return {
                            value: `${item.idMunicipio}`,
                            label: item.nomeMunicipio,
                        }
                    })
                    setListMunicipio(arr);
                    setIsDisabledMunicipio(false);
                }
            }
        }else{
            setListMunicipio([]);
            setMunicipioSelecionado('');
        }
    }

    const changeMunicipio = async (e) => {
        const muni = e.target.value
        setMunicipioSelecionado(muni);
    }

    const salvarProdutor = async () => {
        try {
            setIsVisibleAlert(false);
            let valid = false;

            if (inputNome.length < 3) {
                setIsValidNome(true);
                valid = true;
            } else {
                setIsValidNome(false);
            }

            const ckeckIsValidEmail = validateEmail(inputEmail);
            if (!ckeckIsValidEmail) {
                setIsValidEmail(true);
                valid = true;
            } else {
                setIsValidEmail(false);
            }

            if (inputCelular.length < 15) {
                setIsValidCelular(true);
                valid = true;
            } else {
                setIsValidCelular(false);
            }

            if (ufSelecionado === null || ufSelecionado === '') {
                setIsValidUf(true);
                valid = true;
            } else {
                setIsValidUf(false);
            }
            if (municipioSelecionado === null || municipioSelecionado === '') {
                setIsValidMunicipio(true);
                valid = true;
            } else {
                setIsValidMunicipio(false);
            }

            if (valid) return;

            const permissoesP = [
                { idPermissao: 1 },
                { idPermissao: 2 },
                { idPermissao: 3 },
                { idPermissao: 4 }
            ]

            const objEnderecoP = {
                idEndereco: idEnderecoP,
                idUf: ufSelecionado,
                idMunicipio: municipioSelecionado,
                endereco: inputEndereco !== '' ? inputEndereco : null,
            }

            const fixoSemMascara = retiraMascaraMaterNumeros(inputTelefoneFixo);
            const celularSemMascara = retiraMascaraMaterNumeros(inputCelular);
            const emailP = `sangrador${celularSemMascara}@hevea.com`;

            let response = null;

            if (operacao == 'update') {
                const jsonUpdate = {
                    idUsuario: idUsuarioGerenteP,
                    nome: inputNome,
                    emailPessoal: inputEmail,
                    numeroCelular: celularSemMascara,
                    telefoneFixo: fixoSemMascara,
                    idTipoUsuario: ActionTypes.TIPO_USUARIO_GERENTE,
                    idSafra: idSafraP,
                    idUa: idUaP,
                    objEndereco: objEnderecoP,
                }
                response = await Api.putRequestSemAccessToken(`api/usuario/alterarUsuario`, jsonUpdate);
            } else {
                const jsonUsuario = {
                    nome: inputNome,
                    email: emailP,
                    numeroCelular: celularSemMascara,
                    senha: '123456',
                    emailPessoal: inputEmail,
                    telefoneFixo: fixoSemMascara,
                    idTipoUsuario: ActionTypes.TIPO_USUARIO_GERENTE,
                    permissoes: permissoesP,
                    redefinirSenha: true,
                    idSafra: idSafraP,
                    idUa: idUaP,
                    objEndereco: objEnderecoP,
                }
                response = await Api.postRequestSemAccessToken(`api/usuario/cadastrarUsuarioGerente`, jsonUsuario);
            }

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setNumStatusResposta(response.numeroStatusResposta);
                setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe FormProdutores metodo salvarProdutor', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputCelular(telComMascara);
    }

    const changeTelefonefixo = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputTelefoneFixo(telComMascara);
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderinputs() {
        return (
            <Card direction='column' px='10px' py='10px' borderWidth={3} >
                <Box w={'100%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Nome *</Text>
                            <Input
                                value={inputNome}
                                onChange={(e) => setInputNome(e.target.value)}
                                isInvalid={isValidNome}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Endereço</Text>
                            <Input
                                value={inputEndereco}
                                onChange={(e) => setInputEndereco(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box w={'100%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>E-mail *</Text>
                            <Input
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                                isInvalid={isValidEmail}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="email"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Celular *</Text>
                                <Input
                                    value={inputCelular}
                                    onChange={changeCelular}
                                    isInvalid={isValidCelular}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                            <Box mt={{ base: 4, md: 0 }}>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Telefone Fixo</Text>
                                <Input
                                    value={inputTelefoneFixo}
                                    onChange={changeTelefonefixo}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>
                <Box w={'100%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>UF *</Text>
                            <Select
                                value={ufSelecionado}
                                isInvalid={isValidUf}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeUf}
                            >
                                {LIST_UFS.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Municipio *</Text>
                            <Select
                                value={municipioSelecionado}
                                isInvalid={isValidMunicipio}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeMunicipio}
                                disabled={isDisabledMunicipio}
                            >
                                {listMunicipio.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </Box>
            </Card>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => history.goBack()}>Voltar</Button>
                <Button variant="brand" onClick={salvarProdutor}>Salvar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderMenssage() {
        return (
            <Flex flexDirection={'column'} px='2px' mt='20px' justify='center' align='center'>
                <Text color={'orange.800'} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'OBS: O usuário será criado com uma senha padrão: 123456'}</Text>
                <Text color={'orange.800'} fontSize='15px' fontWeight={'light'} align={'center'}>{'Após logado o usuário pode acessar seu perfil para alteração'}</Text>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' px='5px' py='10px'>
            {renderAlert()}
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>Cadastrar Gerente</Text>
            </Flex>
            {renderinputs()}
            {renderMenssage()}
            {renderButton()}
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={()=> { closeModalAndBoBack() }} />
        </Card>
    );
}

export default FormGerentes;
