import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    SimpleGrid,
    Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';

export default function ListaSeringueirosParaLancamento() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaSeringueirosTarefas, setListaSeringueirosTarefas] = useState([]);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const shadowBlack = ('0px 5px 10px 3px rgba(0, 0, 0, 0.30)');         // 1-posicao horizontal  2-possicao vertical  3-desfoque 4-largura 
    const gradient11 = 'linear-gradient(0deg, white 80%, orange.500, orange.700)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            localStorage.removeItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria');

            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            const idSafra = localStorage.getItem('@HE-idSafra');
            const idUa = localStorage.getItem('@HE-idUa');
            const dtI = localStorage.getItem('@HE-dtInicioSafraAsync');
            const dtInicioSafra = moment(dtI).format('YYYY-MM-DD HH:mm:ss');
            const dtAtual = moment().format('YYYY-MM-DD HH:mm:ss');

            const url = `dashboardWeb/dadosBasicosParaDashboardWeb?idUa=${idUa}&idSafra=${idSafra}&idTipoPessoa=3&dtProgramadoDe=${dtInicioSafra}&dtProgramadoAte=${dtAtual}`;
            //console.log('-------', url);
            
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const val1 = res.somaDasTarefasAndDiasDaSemanaParaCadaSeringueiro;
                setListaSeringueirosTarefas(val1);
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    const lancarTarefa = (item) => {    // NAO APAGAR
        localStorage.setItem('@HE-dadosParaTelaLancamentoDeTarefa', JSON.stringify(item));
        history.push("/admin/lista-tarefas-seringueiro");
    }

    const AcompanhamentoAtividadeSangria = (item) => {
        const initMes = moment().startOf('month').format('YYYY-MM-DD');
        const jsonX = {
            mes: initMes,
            idPessoa: item.idPessoa,
        }
        localStorage.setItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria', JSON.stringify(jsonX));
        localStorage.setItem('@HE-dadosParaTelaLancamentoDeTarefa', JSON.stringify(item));
        history.push("/admin/acompanhamento-atividade-sangria");
    }

    const lancarTarefaAtividadeSangria = (item) => {
        const initMes = moment().startOf('month').format('YYYY-MM-DD');
        const jsonX = {
            mes: initMes,
            idPessoa: item.idPessoa,
        }
        localStorage.setItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria', JSON.stringify(jsonX));
        localStorage.setItem('@HE-dadosParaTelaLancamentoDeTarefa', JSON.stringify(item));
        history.push("/admin/lancar-tarefa-atividade-sangria");
    }

    const lancamentoComBobox = (item) => {
        const initMes = moment().startOf('month').format('YYYY-MM-DD');
        const jsonX = {
            mes: initMes,
            idPessoa: item.idPessoa,
        }
        localStorage.setItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria', JSON.stringify(jsonX));
        localStorage.setItem('@HE-dadosParaTelaLancamentoDeTarefa', JSON.stringify(item));
        history.push("/admin/lancamento-combobox");
    }
    const lancamentoTeste = (item) => {
        const initMes = moment().startOf('month').format('YYYY-MM-DD');
        const jsonX = {
            mes: initMes,
            idPessoa: item.idPessoa,
        }
        localStorage.setItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria', JSON.stringify(jsonX));
        localStorage.setItem('@HE-dadosParaTelaLancamentoDeTarefa', JSON.stringify(item));
        history.push("/admin/lancamento-teste");
    }

    const qualidadeSangria = (item) => {
        const json = {
            idPessoa: item.idPessoa,
            nome: item.nome,
        }
        localStorage.removeItem('@HE-mesSelecionadoTelaQualidadeSangria');  // ESSE CARA TEM QUE FICA AQUI, SO ARMAZENA A DATA NA TELA DE QUALIDADE 
        localStorage.setItem('@HE-dadosParaTelaQualidadeSangria', JSON.stringify(json));
        history.push("/admin/qualidade-sangria");
    }

    function renderSeringueiros() {
        return (
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 3 }} gap='20px' mb='20px' mt={'10px'} px={'10px'}>
                {listaSeringueirosTarefas.length > 0 &&
                    listaSeringueirosTarefas.map((item, index) => {
                        const existeProgramacao = item.isExisteProgramacao != true ? false : true;
                        const percet = item.percentualIndiceDeEficiencia;
                        let corStatus = 'orange';
                        if (percet >= 95) {
                            corStatus = "#00b300";                  // realizada
                        } else if (percet < 95 && percet >= 90) {
                            corStatus = "#DAA520";                  // pendente
                        } else {
                            corStatus = "#FF0000";                  // atrasada
                        }
                        return (
                            <Flex key={index} justify='center' align='center'>
                                <Box
                                    w={'400px'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    boxShadow={shadowBlack}
                                    borderWidth={'3px'}
                                    borderColor={'blackAlpha.600'}
                                    borderRadius={'10px'}>
                                    <Box w={'100%'} position={'relative'} bgGradient={gradient11} borderRadius={'6px'}>
                                        {!existeProgramacao &&
                                            <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                                                <Flex px={'3px'} justify='end' align='center'>
                                                    <Flex flexDirection={'column'} px={'10px'} py={'0px'} justify='center' align='start' bg={'#ffff66'} borderRadius={'10px'} borderWidth={'1px'}>
                                                        <Text color={'black'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Sem Programação'}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        }
                                        <Box w={'100%'} px={'10px'} py={'10px'} >
                                            <Text my={'8px'} color={'black'} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{item.nome}</Text>
                                            <Flex justify={'space-between'} align={'center'}>
                                                <Box px={'8px'} py={'8px'} bg={'white'} borderRadius={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.600'}>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{'Tarefas Realizadas'}</Text>
                                                    <Text color={'green.500'} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'} mt={'5px'}>{item.realizadas}</Text>
                                                </Box>
                                                <Box px={'8px'} py={'8px'} ml={'8px'} bg={'white'} borderRadius={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.600'}>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{'Tarefas Atrasadas'}</Text>
                                                    <Text color={'red'} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'} mt={'5px'}>{item.atrasadas}</Text>
                                                </Box>
                                                <Box px={'8px'} py={'8px'} ml={'8px'} bg={'white'} borderRadius={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.600'}>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{'Tarefas Pendentes'}</Text>
                                                    <Text color={'orange.500'} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'} mt={'5px'}>{item.pendentes}</Text>
                                                </Box>
                                                <Box px={'8px'} py={'8px'} ml={'8px'} bg={'white'} borderRadius={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.600'}>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{'Índice de eficiência'}</Text>
                                                    <Text color={corStatus} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'} mt={'5px'}>{`${item.percentualIndiceDeEficiencia}%`}</Text>
                                                </Box>
                                            </Flex>
                                            <Flex flexDirection={'column'} mt={'20px'} justify={'space-around'} align={'center'}>
                                                <Button
                                                    h={'30px'}
                                                    w={'320px'}
                                                    variant="brand"
                                                    fontSize={'13px'}
                                                    isDisabled={!existeProgramacao}
                                                    onClick={() => { AcompanhamentoAtividadeSangria(item) }}>Acompanhamento das Atividades de Sangria</Button>
                                                <Button
                                                    h={'30px'}
                                                    w={'320px'}
                                                    mt={'10px'}
                                                    variant="brand"
                                                    fontSize={'13px'}
                                                    isDisabled={!existeProgramacao}
                                                    onClick={() => { qualidadeSangria(item) }}>Qualidade Sangria</Button>
                                                {/* <Button 
                                                    h={'30px'} 
                                                    w={'320px'} 
                                                    mt={'10px'} 
                                                    variant="brand" 
                                                    fontSize={'13px'} 
                                                    isDisabled={!existeProgramacao || !sangriaAtiva} 
                                                    onClick={() => { lancarTarefa(item) }}>Lançar Tarefa</Button>
                                                <Button 
                                                    h={'30px'} 
                                                    w={'320px'} 
                                                    mt={'10px'} 
                                                    variant="brand" 
                                                    fontSize={'13px'} 
                                                    isDisabled={!existeProgramacao || !sangriaAtiva} 
                                                    onClick={() => { lancarTarefaAtividadeSangria(item) }}>Lançar Tarefa Atividade Sangria</Button>
                                                <Button 
                                                    h={'30px'} 
                                                    w={'320px'} 
                                                    mt={'10px'} 
                                                    variant="brand" 
                                                    fontSize={'13px'} 
                                                    isDisabled={!existeProgramacao || !sangriaAtiva} 
                                                    onClick={() => { lancamentoComBobox(item) }}>Lançar Tarefa ComboBox</Button>
                                                <Button 
                                                    h={'30px'} 
                                                    w={'320px'} 
                                                    mt={'10px'} 
                                                    variant="brand" 
                                                    fontSize={'13px'} 
                                                    isDisabled={!existeProgramacao || !sangriaAtiva} 
                                                    onClick={() => { lancamentoTeste(item) }}>Lançar Tarefa Teste</Button> */}
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>
                            </Flex>
                        )
                    })}
            </SimpleGrid>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center'>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'}>
                <Box mb={'25px'}>
                    <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>Seringueiros</Text>
                    <Text mt={'5px'} color={'gray.500'} fontSize='15px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>Lista de seringueiros para lançamento de tarefas</Text>
                    <Flex mt={'5px'} justify={'center'} align={'center'}>
                        {!sangriaAtiva && (
                            <Badge colorScheme='red' variant='solid'>Safra finalizada</Badge>
                        )}
                    </Flex>
                </Box>
                {renderSeringueiros()}
            </Card>
        </Box>
    )
}
