import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
    components: {
        Button: {
            baseStyle: {
                borderRadius: "16px",
                boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
                transition: ".25s all ease",
                boxSizing: "border-box",
                _focus: { boxShadow: "none" },
                _active: { boxShadow: "none" },
            },
            variants: {
                outline: () => ({ borderRadius: "16px" }),
                brand: (props) => ({
                    bg: mode("brand.500", "brand.400")(props),
                    color: "white",
                    _focus: { bg: mode("brand.500", "brand.400")(props) },
                    _active: { bg: mode("brand.500", "brand.400")(props) },
                    _hover: { bg: mode("#e67300", "brand.400")(props) },
                    h: "30px",
                }),
                lightBrand: (props) => ({
                    bg: mode("#F2EFFF", "whiteAlpha.100")(props),
                    color: mode("brand.500", "white")(props),
                    fontSize: "13px",
                    _focus: { bg: mode("#F2EFFF", "whiteAlpha.100")(props) },
                    _active: { bg: mode("secondaryGray.300", "whiteAlpha.100")(props) },
                    _hover: { bg: mode("secondaryGray.400", "whiteAlpha.200")(props) },
                    h: "30px",
                }),
                light: (props) => ({
                    bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
                    color: mode("secondaryGray.900", "white")(props),
                    fontSize: "13px",
                    _focus: { bg: mode("secondaryGray.300", "whiteAlpha.100")(props) },
                    _active: { bg: mode("secondaryGray.300", "whiteAlpha.100")(props) },
                    _hover: { bg: mode("secondaryGray.400", "whiteAlpha.200")(props) },
                    h: "30px",
                }),
                setup: (props) => ({
                    fontWeight: "500",
                    fontSize: "13px",
                    borderRadius: "50px",
                    bg: mode("transparent", "brand.400")(props),
                    border: mode("1px solid", "0px solid")(props),
                    borderColor: mode("secondaryGray.400", "transparent")(props),
                    color: mode("secondaryGray.900", "white")(props),
                    _focus: { bg: mode("transparent", "brand.400")(props) },
                    _active: { bg: mode("transparent", "brand.400")(props) },
                    _hover: { bg: mode("secondaryGray.100", "brand.400")(props) },
                    h: "30px",
                }),
                action: (props) => ({
                    fontWeight: "bold",
                    fontSize: "13px",
                    borderRadius: "50px",
                    borderWidth: "1px",
                    borderColor: "orange.700",
                    bg: mode("gray.100", "gray.100")(props),
                    color: mode("orange.700", "orange.700")(props),
                    _focus: { bg: mode("gray.100", "gray.100")(props) },
                    _active: { bg: mode("gray.200", "gray.200")(props) },
                    _hover: { bg: mode("gray.200", "gray.200")(props) },
                    h: "30px",
                }),
                redBrand: (props) => ({
                    bg: mode("red.600", "red.600")(props),
                    color: "white",
                    fontSize: "13px",
                    _focus: { bg: mode("red.600", "red.600")(props) },
                    _active: { bg: mode("red.500", "red.500")(props) },
                    _hover: { bg: mode("red.500", "red.500")(props) },
                    h: "30px",
                }),
                miniBrand: (props) => ({
                    bg: mode("orange.800", "orange.800")(props),
                    color: "white",
                    fontSize: "13px",
                    _focus: { bg: mode("orange.800", "orange.800")(props) },
                    _active: { bg: mode("orange.600", "orange.600")(props) },
                    _hover: { bg: mode("orange.600", "orange.600")(props) },
                    h: "30px",
                }),
            },
        },
    },
};
