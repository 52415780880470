import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    useColorModeValue,
    Flex,
    Wrap,
    WrapItem,
    Button,
    Radio,
    RadioGroup,
    List,
    ListItem,
    SimpleGrid,
    Center,
    Checkbox,
} from '@chakra-ui/react';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { ARRAY_PERMISSOES_MOCK } from 'model/MockData';
import { mascaraTelefone } from '../../../util/Masck';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft, MdExpandMore } from "react-icons/md";
import SelectFilter from 'components/select/SelectFilter';

export default function FormPermissaoAcessosSistema() {

    const [loading, setLoading] = useState(false);
    const [listCombo, setListCombo] = useState([]);
    const [listComboCompleto, setListComboCompleto] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [descTipoUsuario, setDescTipoUsuario] = useState('');
    const [numeroCelular, setNumeroCelular] = useState('');
    const [PRESQUISAR, setPESQUISAR] = useState(true);
    const [SERINGUEIRO, setSERINGUEIRO] = useState(true);
    const [GERENTE, setGERENTE] = useState(false);
    const [PRODUTOR, setPRODUTOR] = useState(false);
    const [ADMIN, setADMIN] = useState(false);
    const [TECNICO, setTECNICO] = useState(false);

    const [valuePermissao, setValuePermissao] = useState('1');
    const [propriedadesSelecionadas, setPropriedadesSelecionadas] = useState([]);
    const [listaPropriedades, setListaPropriedades] = useState([]);
    const [idTipoUsuarioP, setIdTipoUsuarioP] = useState('1');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [checkBoxAdmin, setCheckBoxAdmin] = useState(true);
    const [checkBoxTecnico, setCheckBoxTecnico] = useState(true);
    const [checkBoxProdutor, setCheckBoxProdutor] = useState(true);
    const [checkBoxGerente, setCheckBoxGerente] = useState(true);
    const [checkBoxSeringueiro, setCheckBoxSeringueiro] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.5)', 'unset');
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradienGreen = 'linear-gradient(green.700, green.600, green.500)';
    const gradienGray = 'linear-gradient(gray.300, gray.100, gray.300)';

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);

                setLoading(false);
                await listaUsuarios();

            } catch (error) {
                console.log('Erro na classe FormPermissaoAcessosSistema metodo useEffect', error);
            }
        }
        loaderScreen();
    }, [])

    const listaUsuarios = async () => {
        try {
            const url = `api/usuario/listTodosUsuarios`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                let arrayUser = [];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];

                    const colorBg = item.idPermissao == ActionTypes.ID_PERMISSAO_SERINGUEIRO ? 'gray' : 'black';

                    const obj = {
                        value: `${item.idUsuario}`,
                        label: item.nome,
                        idPermissao: `${item.idPermissao}`,
                        colorBg: colorBg,
                    }
                    arrayUser.push(obj);
                }
                setListComboCompleto(arrayUser);
                const xx = arrayUser.filter(x => x.idPermissao != ActionTypes.ID_PERMISSAO_SERINGUEIRO);
                setListCombo(xx);
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe PermissoesAndAcessosSistema metodo listaUsuarios', error);
        }
    }

    const salvarPermissoes = async () => {
        try {
            const arrayPermissoesP = [
                { idPermissao: 1, descricao: "ROLE_PESQUISAR", status: PRESQUISAR },
                { idPermissao: 2, descricao: "ROLE_GERENTE", status: SERINGUEIRO },
                { idPermissao: 3, descricao: "ROLE_SERINGUEIRO", status: GERENTE },
                { idPermissao: 4, descricao: "ROLE_PRODUTOR", status: PRODUTOR },
                { idPermissao: 5, descricao: "ROLE_ADMIN", status: ADMIN },
                { idPermissao: 6, descricao: "ROLE_TECNICO", status: TECNICO },
            ]

            const jsonPermissoes = {
                idUsuario: usuarioSelecionado,
                arrayPermissoes: arrayPermissoesP,
                listUaDto: propriedadesSelecionadas,
                idTipoUsuario: idTipoUsuarioP
            }

            const response = await Api.putRequest(`api/usuario/redefinirPermissoesUsuario`, jsonPermissoes);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe FormPermissaoAcessosSistema metodo salvarPermissoes', error);
        }
    }

    const changeRadio = (e) => {
        setValuePermissao(e);

        if (e == '1') {
            setSERINGUEIRO(true);
            setGERENTE(false);
            setPRODUTOR(false);
            setTECNICO(false);
            setADMIN(false);
        } else if (e == '2') {
            setSERINGUEIRO(true);
            setGERENTE(true);
            setPRODUTOR(false);
            setTECNICO(false);
            setADMIN(false);
        } else if (e == '3') {
            setSERINGUEIRO(true);
            setGERENTE(true);
            setPRODUTOR(true);
            setTECNICO(false);
            setADMIN(false);
        } else if (e == '4') {
            setSERINGUEIRO(true);
            setGERENTE(true);
            setPRODUTOR(true);
            setTECNICO(true);
            setADMIN(false);
        } else if (e == '5') {
            setSERINGUEIRO(true);
            setGERENTE(true);
            setPRODUTOR(true);
            setTECNICO(true);
            setADMIN(true);
        } else {
            console.log('aaaa');
        }
    }

    const moveParaPropriedadesSelecionadas = (item) => {
        setListaPropriedades(listaPropriedades.filter(i => i.idUa !== item.idUa));
        setPropriedadesSelecionadas([...propriedadesSelecionadas, item]);
    };

    const moveParaPropriedades = (item) => {
        setPropriedadesSelecionadas(propriedadesSelecionadas.filter(i => i.idUa !== item.idUa));
        setListaPropriedades([...listaPropriedades, item]);
    };

    async function moverParaSelecionadas() {
        const arraysConcatenados = listaPropriedades.concat(propriedadesSelecionadas);
        setPropriedadesSelecionadas(arraysConcatenados);
        setListaPropriedades([]);
    }

    async function moverParaPropriedades() {
        const arraysConcatenados = propriedadesSelecionadas.concat(listaPropriedades);
        setListaPropriedades(arraysConcatenados);
        setPropriedadesSelecionadas([]);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const atualizaTela = (array = [], val) => {
        if (array.length > 0) {
            const user = array[0];

            const celMascarado = user.numeroCelular != null && user.numeroCelular != '' ? mascaraTelefone(user.numeroCelular) : '';
            setDescTipoUsuario(user.descTipoUsuario);
            setNomeUsuario(user.nome);
            setNumeroCelular(celMascarado);

            let pesquisar = false;
            let gerente = false;
            let seringueiro = false;
            let produtor = false;
            let admin = false;
            let tecnico = false;

            if (user.listUsuarioPermissao.length > 0) {
                const arrPerm = user.listUsuarioPermissao;

                ARRAY_PERMISSOES_MOCK.map((item, index) => {

                    if (arrPerm[index] != undefined) {
                        const xx = arrPerm[index].descricao;
                        if (xx === 'ROLE_PESQUISAR') pesquisar = true;
                        if (xx === 'ROLE_GERENTE') gerente = true;
                        if (xx === 'ROLE_SERINGUEIRO') seringueiro = true;
                        if (xx === 'ROLE_PRODUTOR') produtor = true;
                        if (xx === 'ROLE_ADMIN') admin = true;
                        if (xx === 'ROLE_TECNICO') tecnico = true;
                    }
                })
                setPESQUISAR(pesquisar);
                setSERINGUEIRO(gerente);
                setGERENTE(seringueiro);
                setPRODUTOR(produtor);
                setADMIN(admin);
                setTECNICO(tecnico);

                if (seringueiro) setValuePermissao('1');
                if (gerente) setValuePermissao('2');
                if (produtor) setValuePermissao('3');
                if (tecnico) setValuePermissao('4');
                if (admin) setValuePermissao('5');
            }
        }
    }

    const changeCheckBox = async (e) => {
        try {
            setLoading(true);
            const val = e.target.value;
            const filtrosAtivos = [];

            let isAdmin = checkBoxAdmin;
            let isATecn = checkBoxTecnico;
            let isProdu = checkBoxProdutor;
            let isGerer = checkBoxGerente;
            let isSerin = checkBoxSeringueiro;

            if (val == ActionTypes.ID_PERMISSAO_ADMIN) {
                setCheckBoxAdmin(!checkBoxAdmin);
                isAdmin = !checkBoxAdmin;
            }
            if (val == ActionTypes.ID_PERMISSAO_TECNICO) {
                setCheckBoxTecnico(!checkBoxTecnico);
                isATecn = !checkBoxTecnico;
            }
            if (val == ActionTypes.ID_PERMISSAO_PRODUTOR) {
                setCheckBoxProdutor(!checkBoxProdutor);
                isProdu = !checkBoxProdutor;
            }
            if (val == ActionTypes.ID_PERMISSAO_GERENTE) {
                setCheckBoxGerente(!checkBoxGerente);
                isGerer = !checkBoxGerente;
            }
            if (val == ActionTypes.ID_PERMISSAO_SERINGUEIRO) {
                setCheckBoxSeringueiro(!checkBoxSeringueiro);
                isSerin = !checkBoxSeringueiro;
            }

            if (isAdmin) filtrosAtivos.push(ActionTypes.ID_PERMISSAO_ADMIN);
            if (isATecn) filtrosAtivos.push(ActionTypes.ID_PERMISSAO_TECNICO);
            if (isProdu) filtrosAtivos.push(ActionTypes.ID_PERMISSAO_PRODUTOR);
            if (isGerer) filtrosAtivos.push(ActionTypes.ID_PERMISSAO_GERENTE);
            if (isSerin) filtrosAtivos.push(ActionTypes.ID_PERMISSAO_SERINGUEIRO);

            const xx = listComboCompleto.filter((p) => filtrosAtivos.includes(p.idPermissao));

            console.log('+++++++', xx);

            setListCombo(xx);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe FormPermissaoAcessosSistema metodo changeCheckBox', error);
        }
    }

    const handleSelect = async (item) => {
        setLoading(true);
        setTimeout(() => {
            handleSelectSec(item);
        }, 1000);
    }

    const handleSelectSec = async (item) => {
        try {
            const idUser = item.value;
            setUsuarioSelecionado(`${idUser}`);

            if (idUser != '') {
                setLoading(true);
                const url = `api/usuario/listaTodosOsUsuariosAndPermissoesAndAcessos?idUsuario=${idUser}`;
                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    setIdTipoUsuarioP(res[0].idTipoUsuario);

                    const listUaOndeUsuarioTemAcesso = res[0].listUaOndeUsuarioTemAcessoDto;
                    const listUaCompleta = res[0].listUaCompleta;

                    setListaPropriedades(listUaCompleta);
                    setPropriedadesSelecionadas(listUaOndeUsuarioTemAcesso);

                    atualizaTela(res, idUser);
                    setLoading(false);
                }
            } else {
                setPESQUISAR(false);
                setSERINGUEIRO(false);
                setGERENTE(false);
                setPRODUTOR(false);
                setADMIN(false);
                setTECNICO(false);

                setDescTipoUsuario('');
                setNomeUsuario('');
                setNumeroCelular('');
                setValuePermissao('1');

                setListaPropriedades([]);
                setPropriedadesSelecionadas([]);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe FormPermissaoAcessosSistema metodo handleSelectSec', error);
        }
    };

    function renderSelectUsuarios() {
        return (
            <Flex flexDirection={'column'} px='25px' justify='center' align='start'>
                <SelectFilter items={listCombo} onSelect={handleSelect} titulo={'Usuários'} />
                <Flex mt={'5px'} ml={'0px'} w={'250px'} py={'6px'} justify={'space-evenly'} align={'center'} borderRadius={'8px'} >
                    <Checkbox
                        size="md"
                        colorScheme='green'
                        isChecked={checkBoxSeringueiro}
                        value={ActionTypes.ID_PERMISSAO_SERINGUEIRO}
                        onChange={changeCheckBox}
                        borderWidth={'1px'}
                        borderColor={'blackAlpha.500'} />
                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Incluir seringueiros na lista'}</Text>
                </Flex>
            </Flex>
        )
    }

    function renderDadosUsuario() {
        return (
            <Box my={'20px'} mx={'10px'} boxShadow={cardShadow} borderRadius={'10px'} bgGradient={gradienGray}>
                <Wrap spacing='2px' py={'5px'} px={'15px'} justify={'space-around'} align={'center'}>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='16px' fontWeight='bold' lineHeight={'100%'}>Usuário</Text>
                            <Text color={'gray.500'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{nomeUsuario}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='16px' fontWeight='bold' lineHeight={'100%'}>Celular</Text>
                            <Text color={'gray.500'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{numeroCelular}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem>
                        <Flex h={'45px'} flexDirection={'column'} justify={'space-between'} align={'center'}>
                            <Text color={'gray.900'} fontSize='16px' fontWeight='bold' lineHeight={'100%'}>Perfil</Text>
                            <Text color={'gray.500'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{descTipoUsuario}</Text>
                        </Flex>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRarioGrupo() {
        return (
            <Box mx={'10px'} mt={'0px'} borderWidth={'2px'} borderColor={'blackAlpha.400'} borderRadius={'10px'} bg={'white'}>
                <RadioGroup onChange={(e) => { changeRadio(e) }} value={valuePermissao}>
                    <Wrap spacing='2px' py={'10px'} px={'15px'} justify={'space-between'} align={'center'}>
                        <WrapItem>
                            <Flex w='150px' justify={'center'} align={'center'}>
                                <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiro</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex w='150px' justify={'center'} align={'center'}>
                                <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Gerente</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex w='150px' justify={'center'} align={'center'}>
                                <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Produtor</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex w='150px' justify={'center'} align={'center'}>
                                <Radio value='4' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Técnico</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                        <WrapItem>
                            <Flex w='150px' justify={'center'} align={'center'}>
                                <Radio value='5' size="lg" borderWidth={'1px'} borderColor={'black'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Admin</Text>
                                </Radio>
                            </Flex>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Box>
        )
    }

    function renderDulListBox() {
        return (
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='15px' mb='20px' mt={'20px'}>
                <Flex justify="center" align={'center'}>
                    <Box
                        width="95%"
                        bg={'white'}
                        borderWidth={'2px'}
                        borderRadius={'10px'}
                        borderColor={'blackAlpha.500'}>
                        <Box position={'relative'} bgGradient={gradientHeader} borderTopRadius={'8px'}>
                            <Flex justify={'center'} align={'center'}>
                                <Text mb={'1px'} py={'10px'} color={'white'} fontSize='17px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Propriedades'}</Text>
                                <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                                    <TooltipIcon
                                        label={
                                            <Box py={'5px'} pb={'10px'}>
                                                <Text align={'center'} lineHeight={'100%'}>{'Propriedades'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Lista todas as propriedades cadastradas no sistema.'}</Text>
                                            </Box>
                                        }
                                        height={'18px'} color={'white'} />
                                </Center>
                            </Flex>
                            <Box position={'absolute'} top={'2px'} right={'25px'}>
                                <Button
                                    size="sm"
                                    color={'blue.800'}
                                    variant="solid"
                                    h={'30px'}
                                    w={'45px'}
                                    bg={'whiteAlpha.800'}
                                    rightIcon={<MdKeyboardDoubleArrowRight size={'25px'} />}
                                    onClick={() => { moverParaSelecionadas() }} />
                            </Box>
                        </Box>
                        <List px={'10px'} spacing={3} h={'400px'} overflowY={'auto'}>
                            {listaPropriedades.map((item, index) => {
                                const proprietario = item.idUsuario == usuarioSelecionado ? true : false;
                                return (
                                    <ListItem key={index} bg={'blackAlpha.200'} borderRadius={'8px'}>
                                        <Flex justify="space-between" align="center">
                                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{item.nomeFazenda}</Text>
                                            <Flex justify="end" align="center">
                                                {proprietario && <Text mr={'10px'} color={'green.500'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Proprietario'}</Text>}
                                                <Button
                                                    size="sm"
                                                    color={'blue.800'}
                                                    variant="solid"
                                                    h={'30px'}
                                                    bg={'blackAlpha.300'}
                                                    rightIcon={<FaChevronRight />}
                                                    onClick={() => moveParaPropriedadesSelecionadas(item)} />
                                            </Flex>
                                        </Flex>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                </Flex>
                <Flex justify="center" align={'center'}>
                    <Box
                        width="95%"
                        bg={'white'}
                        borderWidth={'2px'}
                        borderRadius={'10px'}
                        borderColor={'blackAlpha.500'}>
                        <Box position={'relative'} bgGradient={gradienGreen} borderTopRadius={'8px'}>
                            <Flex justify={'center'} align={'center'}>
                                <Text mb={'1px'} py={'10px'} color={'white'} fontSize='17px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Permitir Acesso'}</Text>
                                <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                                    <TooltipIcon
                                        label={
                                            <Box py={'5px'} pb={'10px'}>
                                                <Text align={'center'} lineHeight={'100%'}>{'Propriedades'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Adicione ou remova as propriedades que pretende conceder acesso ao usuário.'}</Text>
                                            </Box>
                                        }
                                        height={'18px'} color={'white'} />
                                </Center>
                            </Flex>
                            <Box position={'absolute'} top={'2px'} left={'10px'} >
                                <Button
                                    size="sm"
                                    color={'blue.800'}
                                    variant="solid"
                                    h={'30px'}
                                    w={'45px'}
                                    bg={'whiteAlpha.800'}
                                    leftIcon={<MdKeyboardDoubleArrowLeft size={'25px'} />}
                                    onClick={() => { moverParaPropriedades() }} />
                            </Box>
                        </Box>
                        <List px={'10px'} spacing={3} h={'400px'} overflowY={'auto'} >
                            {propriedadesSelecionadas.map((item, index) => {
                                const proprietario = item.idUsuario == usuarioSelecionado ? true : false;
                                return (
                                    <ListItem key={index} bg={'rgba(0, 204, 0, 0.1)'} borderRadius={'8px'}>
                                        <Flex justify="space-between" align="center" >
                                            <Flex justify="start" align="center" >
                                                <Button
                                                    size="sm"
                                                    color={'blue.800'}
                                                    variant="solid"
                                                    h={'30px'}
                                                    bg={'blackAlpha.300'}
                                                    leftIcon={<FaChevronLeft />}
                                                    onClick={() => moveParaPropriedades(item)} />
                                                <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{item.nomeFazenda}</Text>
                                            </Flex>
                                            {proprietario && <Text mr={'10px'} color={'green.500'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'end'}>{'Proprietario'}</Text>}
                                        </Flex>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                </Flex>
            </SimpleGrid>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='end'>
                <Button isDisabled={usuarioSelecionado != '' ? false : true} variant="brand" onClick={salvarPermissoes}>Salvar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'1px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card w='100%' px='0px' pt={'10px'}>
                {isVisibleAlert && renderAlert()}
                <Text mb={'10PX'} color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'}>Gerenciamento de Perfil</Text>
                {renderSelectUsuarios()}
                {renderDadosUsuario()}
                <Card
                    px={'10px'}
                    py={'10px'}
                    pointerEvents={usuarioSelecionado != '' ? 'auto' : 'none'}
                    opacity={usuarioSelecionado != '' ? '1' : '0.5'}>
                    {renderRarioGrupo()}
                    {renderDulListBox()}
                    <Flex flexDirection={'column'} mt={'20px'} align='center' justifyContent={'center'} >
                        <Text color={'orange.400'} fontSize='25px' fontWeight='bold' lineHeight='100%' align={'center'}>{'Observação'}</Text>
                        <Text color={'orange.400'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'center'}>{'O usuário com perfil alterado precisa efetuar login novamente para carregar as novas permissões'}</Text>
                    </Flex>
                </Card>
                {renderButton()}
                {loading && <Loader isOpen={loading} />}
            </Card>
        </Box>
    );
}
