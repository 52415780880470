import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    SimpleGrid,
    Box,
    Icon,
    Image,
    Stat,
    StatNumber,
    StatLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import { IoEllipse } from "react-icons/io5";
import * as ActionTypes from '../../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';

export default function StatusDiario() {

    const header = useRef();
    const pdfRef02 = useRef(null);
    const pdfRef03 = useRef(null);
    const pdfRef04 = useRef(null);
    const pdfRef05 = useRef(null);
    const pdfRef06 = useRef(null);
    const pdfRef07 = useRef(null);

    const [arraySeringueiros01, setArraySeringueiros01] = useState([]);
    const [arraySeringueiros02, setArraySeringueiros02] = useState([]);
    const [arraySeringueiros03, setArraySeringueiros03] = useState([]);
    const [arraySeringueiros04, setArraySeringueiros04] = useState([]);
    const [arraySeringueiros05, setArraySeringueiros05] = useState([]);
    const [arraySeringueiros06, setArraySeringueiros06] = useState([]);
    const [arraySeringueiros07, setArraySeringueiros07] = useState([]);

    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [resumoTarefa, setResumoTarefa] = useState(0);
    const [indiceDeEficiencia, setIndiceDeEficiencia] = useState(0);
    const [corIndiceDeEficiencia, setCorIndiceDeEficiencia] = useState("green.500");
    const [tarefasPendetes, setTarefasPendetes] = useState(0);
    const [tarefasPendetesMenosSeringueirosDeletados, setTarefasPendetesMenosSeringueirosDeletados] = useState(0);
    const [tarefasAtrasadasMenosSeringueirosDeletados, setTarefasAtrasadasMenosSeringueirosDeletados] = useState(0);
    const [listaIndicadores, setListaIndicadores] = useState([]);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [marginText, setMarginText] = useState('0px');

    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Status-diario');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const colorRealizada = useColorModeValue("green.500", "green.500");
    const colorPendente = useColorModeValue("orange.500", "orange.500");
    const colorMaoRelizada = useColorModeValue("red.500", "red.500");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(gray.700, gray.600, gray.500)';
    const gradienGray = 'linear-gradient(gray.200, gray.100, gray.200)';


    useEffect(() => {
        async function loaderScreen() {
            localStorage.removeItem('@HE-propriedadeParaEdicao');
            await carregarInformacoesDaTela();
        }
        loaderScreen();
    }, [])

    async function carregarInformacoesDaTela() {
        try {
            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Status-diario-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            const idUf = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const nmUa = localStorage.getItem('@HE-nomeUa');
            const nmSafra = localStorage.getItem('@HE-nomeSafra');

            setNomeMesCorrente(`${nmMes}/${ano}`);
            setNomeUa(nmUa);
            setNomeMunicipio(nomeMunicipioP);
            setIdUf(idUf);

            const dtF = moment().format('YYYY-MM-DD HH:mm:ss');
            const dtX = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
            const dtInit = moment(dtX).subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');
            
            const url = `api/listaDadosParaOperacaoOffline/dadosBasicosParaOperacaoOFFLINE?idUa=${ua}&idSafra=${idSafra}&idTipoPessoa=3&dtProgramadoDe=${dtInit}&dtProgramadoAte=${dtF}`;
            const response = await Api.getAllSemAccessToken(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                const objSe2 = await res.controleSangriaAndTarefasAtrasadasAndTarefasForaDoPrazo;
                const arrResumoTarefasSeringueiro = await res.somaDasTarefasAndDiasDaSemanaParaCadaSeringueiro;

                if (objSe2.length > 0) {

                    objSe2.sort((a, b) => moment(b.dtProgramado).valueOf() - moment(a.dtProgramado).valueOf());
                    const dataMaisAtual = moment(objSe2[0].dtProgramado).format('YYYY-MM-DD');
                    const filtroTarefasDeHoje = objSe2.filter(r => moment(r.dtProgramado).format('YYYY-MM-DD') == dataMaisAtual);

                    if (filtroTarefasDeHoje.length > 0) {
                        let ss = null;

                        await new Promise(resolve => {
                            resolve(
                                ss = filtroTarefasDeHoje.map(x => {
                                    const resumoSerin = arrResumoTarefasSeringueiro.filter(r => r.idPessoa == x.idPessoa);
                                    let realizadas = 0;
                                    let pendentes = 0;
                                    let atrasadas = 0;
                                    let naoRelizadas = 0;
                                    let totalTarefas = 0;
                                    let totalSemFaltas = 0;
                                    let percentualIndiceDeEficiencia = 0;

                                    if (resumoSerin.length > 0) {
                                        const zz = resumoSerin[0];

                                        realizadas = zz.realizadas != null && zz.realizadas != '' ? zz.realizadas : 0;
                                        pendentes = zz.pendentes != null && zz.pendentes != '' ? zz.pendentes : 0;
                                        atrasadas = zz.atrasadas != null && zz.atrasadas != '' ? zz.atrasadas : 0;
                                        naoRelizadas = zz.naoRelizadas != null && zz.naoRelizadas != '' ? zz.naoRelizadas : 0;
                                        totalTarefas = zz.totalTarefas != null && zz.totalTarefas != '' ? zz.totalTarefas : 0;
                                        totalSemFaltas = zz.totalSemFaltas != null && zz.totalSemFaltas != '' ? zz.totalSemFaltas : 0;
                                        percentualIndiceDeEficiencia = zz.percentualIndiceDeEficiencia != null && zz.percentualIndiceDeEficiencia != '' ? zz.percentualIndiceDeEficiencia : 0;
                                    }
                                    x['realizadas'] = realizadas;
                                    x['pendentes'] = pendentes;
                                    x['atrasadas'] = atrasadas;
                                    x['naoRelizadas'] = naoRelizadas;
                                    x['totalTarefas'] = totalTarefas;
                                    x['totalSemFaltas'] = totalSemFaltas;
                                    x['percentualIndiceDeEficiencia'] = percentualIndiceDeEficiencia;
                                    return x;
                                })
                            )
                        });
                        const arrEditavel = objSe2.filter(r => r.editavel == 1)

                        let arrComResumo = null;
                        if (arrEditavel.length > 0) {
                            const trEditavel = arrEditavel[0];
                            const dtProgramadoDoDia = moment(trEditavel.dtProgramado).format('YYYY-MM-DD');
                            await new Promise(resolve => {
                                resolve(
                                    arrComResumo = ss.map(x => {
                                        let bbx = '';
                                        const resumoSerin = objSe2.filter(r => r.idPessoa == x.idPessoa);

                                        let count = resumoSerin.length;
                                        let arraytarefasDoDia = [];
                                        let tarefasRealizadasNodia = '';

                                        if (resumoSerin.length > 0) {

                                            resumoSerin.sort((a, b) => moment(a.dtProgramado).valueOf() - moment(b.dtProgramado).valueOf());
                                            resumoSerin.forEach((element, index) => {

                                                const aaa = moment(element.dtRealizado).format('YYYY-MM-DD');
                                                if (dtProgramadoDoDia == aaa || element.editavel == 1) {

                                                    const obj = {
                                                        letraTarefa: element.letraTarefa,
                                                        pontoVermelho: element.pontoVermelho,
                                                        dsStatus: element.dsStatus,
                                                        idStatusTarefa: element.idStatusTarefa,
                                                        idMotivo: element.idMotivo,
                                                        descMotivo: element.descMotivo != null && element.descMotivo != '' ? element.descMotivo : '',
                                                    }
                                                    arraytarefasDoDia.push(obj);

                                                    if (element.idStatusTarefa == 1 && element.pontoVermelho == 1) {
                                                        if (index <= count) {
                                                            bbx = `${bbx}${element.letraTarefa}`
                                                        }
                                                        if (index < count - 1) {
                                                            bbx = `${bbx}+`
                                                        }
                                                    }
                                                } else {
                                                    if (element.idStatusTarefa == 3 || element.idStatusTarefa == 4) {
                                                        if (index <= count) {
                                                            bbx = `${bbx}${element.letraTarefa}`
                                                        }
                                                        if (index < count - 1) {
                                                            bbx = `${bbx}+`
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        if (bbx.length > 0) {
                                            tarefasRealizadasNodia = bbx;
                                        }
                                        x['arraytarefasDoDia'] = arraytarefasDoDia;
                                        x['tarefasRealizadasNodia'] = tarefasRealizadasNodia;
                                        return x;
                                    })
                                )
                            });

                            arrComResumo.sort(function (a, b) {
                                if (a.nomePessoa.toLowerCase() < b.nomePessoa.toLowerCase()) return -1;
                                if (a.nomePessoa.toLowerCase() > b.nomePessoa.toLowerCase()) return 1;
                                return 0;
                            })
                            setListaIndicadores(arrComResumo);
                            await carregaArrays(arrComResumo);
                            setTelaCarregada(true);
                        }
                    }
                }

                const sonmaGeral = await res.somaDosDadosGeraisDaSafra;
                const naoRealizad = sonmaGeral.faltas != null ? sonmaGeral.faltas : 0;
                setTarefasPendetes(sonmaGeral.tarefasPendentes);
                setTarefasPendetesMenosSeringueirosDeletados(sonmaGeral.tarefasPendentesMenosSeringueirosDeletados);

                const percet = sonmaGeral.percentualIndiceEficiencia;
                let corStatus = colorRealizada;
                if (percet >= 95) {
                    corStatus = colorRealizada;
                } else if (percet < 95 && percet >= 90) {
                    corStatus = colorPendente;
                } else {
                    corStatus = colorMaoRelizada;
                }

                const r = Number(sonmaGeral.tarefasAtrasadasMenosSeringueirosDeletados);
                const x = Number(sonmaGeral.atrasadas);
                const y = Number(naoRealizad);
                const h = x + y;
                const zzx = r + y;

                setResumoTarefa(h);
                setTarefasAtrasadasMenosSeringueirosDeletados(zzx);
                setCorIndiceDeEficiencia(corStatus);
                setIndiceDeEficiencia(sonmaGeral.percentualIndiceEficiencia);

                const filtroTarefasAtrasadas = objSe2.filter(r => r.idStatusTarefa == 3 || r.idStatusTarefa == 4)

                let letr = '';
                if (filtroTarefasAtrasadas.length > 0) {
                    let count = filtroTarefasAtrasadas.length;
                    await new Promise(resolve => {
                        resolve(
                            filtroTarefasAtrasadas.map((item, index) => {
                                if (index <= count) {
                                    letr = `${letr}${item.letraTarefa}`
                                }
                                if (index < count - 1) {
                                    letr = `${letr}+`
                                }
                            })
                        )
                    });
                }
                setTelaCarregada(true);
            } else {
                setTelaCarregada(true);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setTelaCarregada(true);
            console.log('Erro na classe StatusDiario metodo carregarInformacoesDaTela', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    async function carregaArrays(arr = []) {

        if (arr.length > 0) {
            let array01 = [];
            let array02 = [];
            let array03 = [];
            let array04 = [];
            let array05 = [];
            let array06 = [];
            let array07 = [];

            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];

                if (i < 9) {
                    array01.push(item);
                } else if (i >= 10 && i < 20) {
                    array02.push(item);
                } else if (i >= 20 && i < 30) {
                    array03.push(item);
                } else if (i >= 30 && i < 40) {
                    array04.push(item);
                } else if (i >= 40 && i < 50) {
                    array05.push(item);
                } else if (i >= 50 && i < 60) {
                    array06.push(item);
                } else if (i >= 60 && i < 70) {
                    array07.push(item);
                }
            }

            setArraySeringueiros01(array01);
            setArraySeringueiros02(array02);
            setArraySeringueiros03(array03);
            setArraySeringueiros04(array04);
            setArraySeringueiros05(array05);
            setArraySeringueiros06(array06);
            setArraySeringueiros07(array07);
        }
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {

        let imgData0 = null;
        let imgHeigh0 = 0;
        let imgWWIDTH0 = 0;

        let imgData2 = null;
        let imgHeigh2 = 0;

        let imgData3 = null;
        let imgHeigh3 = 0;

        let imgData4 = null;
        let imgHeigh4 = 0;

        let imgData5 = null;
        let imgHeigh5 = 0;

        let imgData6 = null;
        let imgHeigh6 = 0;

        let imgData7 = null;
        let imgHeigh7 = 0;

        const cabecalho = header.current;

        await html2canvas(cabecalho).then((canvas) => {
            imgData0 = canvas.toDataURL('image/png');
            imgHeigh0 = canvas.height;
            imgWWIDTH0 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH0, pdfHeigh / imgHeigh0);
        pdf.addImage(imgData0, 'PNG', 5, 10, pdfWidth - 10, imgHeigh0 * ratio1);

        if (arraySeringueiros02.length > 0) {
            const input2 = pdfRef02.current;
            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }
        if (arraySeringueiros03.length > 0) {
            const input3 = pdfRef03.current;
            await html2canvas(input3).then((canvas) => {
                imgData3 = canvas.toDataURL('image/png');
                imgHeigh3 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData3, 'PNG', 5, 10, pdfWidth - 10, imgHeigh3 * ratio1);
            });
        }
        if (arraySeringueiros04.length > 0) {
            const input4 = pdfRef04.current;
            await html2canvas(input4).then((canvas) => {
                imgData4 = canvas.toDataURL('image/png');
                imgHeigh4 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData4, 'PNG', 5, 10, pdfWidth - 10, imgHeigh4 * ratio1);
            });
        }
        if (arraySeringueiros05.length > 0) {
            const input5 = pdfRef05.current;
            await html2canvas(input5).then((canvas) => {
                imgData5 = canvas.toDataURL('image/png');
                imgHeigh5 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData5, 'PNG', 5, 10, pdfWidth - 10, imgHeigh5 * ratio1);
            });
        }
        if (arraySeringueiros06.length > 0) {
            const input6 = pdfRef06.current;
            await html2canvas(input6).then((canvas) => {
                imgData6 = canvas.toDataURL('image/png');
                imgHeigh6 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData6, 'PNG', 5, 10, pdfWidth - 10, imgHeigh6 * ratio1);
            });
        }
        if (arraySeringueiros07.length > 0) {
            const input7 = pdfRef07.current;
            await html2canvas(input7).then((canvas) => {
                imgData7 = canvas.toDataURL('image/png');
                imgHeigh7 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData7, 'PNG', 5, 10, pdfWidth - 10, imgHeigh7 * ratio1);
            });
        }
        pdf.save(`${nomeRelatorio}.pdf`);
    }

    function renderCards() {
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='10px' mt={'15px'} px={'3'}>
                <Card py='10px' align='center' borderWidth={'2px'} justify={'start'} border={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.600'} bgGradient={gradienGray}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Resumo das tarefas Atrasadas'}</StatLabel>
                            <StatNumber mb={marginText} color={'red.500'} fontSize={{ base: "xl", }}>{resumoTarefa}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' borderWidth={'2px'} justify={'start'} border={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.600'} bgGradient={gradienGray}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Pendentes do dia'}</StatLabel>
                            <StatNumber mb={marginText} color={colorPendente} fontSize={{ base: "xl", }}>{tarefasPendetesMenosSeringueirosDeletados}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' borderWidth={'2px'} justify={'start'} border={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.600'} bgGradient={gradienGray}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Índice de eficiência'}</StatLabel>
                            <StatNumber mb={marginText} color={corIndiceDeEficiencia} fontSize={{ base: "xl", }}>{`${indiceDeEficiencia}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderSeringueiros(arrayP) {
        const bgCard = 'blackAlpha.200';
        return (
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='10px' mb='10px' mt={'15px'} px={'5px'}>
                {arrayP.map((item, index) => {

                    const xx = item.tarefasRealizadasNodia.length;
                    const tarefasRealizadasNodia = xx > 0 ? 'Tarefas Atrasadas +' : 'Sem Atrasadas';
                    const colorAtrasadas = xx > 0 ? 'red' : colorRealizada;
                    const zz = `${item.tarefasRealizadasNodia}${item.letraTarefa}`;
                    const tarefasPendentesAndTarefaDoDia = xx > 1 ? zz : '';
                    const deletado = item.deletado != true ? false : true;
                    const percet = item.percentualIndiceDeEficiencia;
                    let corStatus = colorRealizada;
                    if (percet >= 95) {
                        corStatus = colorRealizada;
                    } else if (percet < 95 && percet >= 90) {
                        corStatus = colorPendente;
                    } else {
                        corStatus = colorMaoRelizada;
                    }
                    return (
                        <Box
                            key={index}
                            borderRadius='15px'
                            borderWidth={'2px'}
                            borderColor={'blackAlpha.600'}
                            mt={'20px'}
                            bg={'white'}
                        >
                            <Box w={'100%'} position={'relative'}>
                                <Flex px='0px' py={'5px'} justify='space-between' align='center' bgGradient={gradientHeader} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'}>
                                    <Box w={'100%'}>
                                        <Text mb={marginText} color={'white'} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.nomePessoa}</Text>
                                    </Box>
                                </Flex>
                                <Box position={'absolute'} top={'3px'} right={'5px'}>
                                    <Box>
                                        {deletado ?
                                            <Text px={'10px'} py={'2px'} bg={'red.600'} borderRadius={'10px'} color={'white'} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{'Desativado'}</Text>
                                            :
                                            <Text color={'white'} fontSize='18px' fontWeight={'bold'} lineHeight='100%'>{''}</Text>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            <Text mb={marginText} color={'gray.600'} pt={'5px'} fontSize='15px' fontWeight='700' lineHeight='100%' align={'center'}>Programação do dia</Text>
                            <Box bgGradient={gradienGray} borderRadius={'10px'} py={'5px'} mb={'10px'} mx={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.400'}>
                                {item.arraytarefasDoDia.length > 0 ?
                                    item.arraytarefasDoDia.map((re, y) => {
                                        return (
                                            <Flex key={y} px='10px' justify='space-around' align='center'>
                                                <Box>
                                                    <Text mb={marginText} color={colorAtrasadas} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{tarefasRealizadasNodia}</Text>
                                                </Box>
                                                <Box>
                                                    {re.idStatusTarefa == 1 &&
                                                        <Box w={'100%'}>
                                                            {re.pontoVermelho == true ?
                                                                <Box w={'100%'} >
                                                                    <Flex px='15px' justify='flex-start' align='flex-end'>
                                                                        <Text color={colorRealizada} fontSize='20px' fontWeight={'bold'} lineHeight='100%'>{re.letraTarefa}</Text>
                                                                        <Icon as={IoEllipse} width='20px' height='20px' color='red' ml={'10px'} />
                                                                        <Text mb={marginText} color={colorRealizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{`Realizada em ${moment(re.dtRealizado).format('DD/MM/YYYY')}`}</Text>
                                                                    </Flex>
                                                                </Box>
                                                                :
                                                                <Box w={'100%'} >
                                                                    <Flex px='15px' justify='center' align='center'>
                                                                        <Box>
                                                                            <Text color={colorRealizada} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{re.letraTarefa}</Text>
                                                                            <Text mb={marginText} color={colorRealizada} fontSize='10px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Tarefa'}</Text>
                                                                        </Box>
                                                                        <Text mb={marginText} color={colorRealizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{'Realizado no dia programado'}</Text>
                                                                    </Flex>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    }
                                                    {re.idStatusTarefa == 2 &&
                                                        <Box w={'100%'}>
                                                            <Flex px='15px' justify='center' align='center'>
                                                                <Box>
                                                                    <Text color={colorPendente} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{re.letraTarefa}</Text>
                                                                    <Text mb={marginText} color={colorPendente} fontSize='10px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Tarefa'}</Text>
                                                                </Box>
                                                                <Text mb={marginText} color={colorPendente} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{`${re.dsStatus} do dia`}</Text>
                                                            </Flex>
                                                        </Box>
                                                    }
                                                    {re.idStatusTarefa == 3 &&
                                                        <Box w={'100%'}>
                                                            <Flex px='15px' justify='flex-start' align='flex-end'>
                                                                <Text mb={marginText} color={colorMaoRelizada} fontSize='20px' fontWeight={'bold'} lineHeight='100%'>{re.letraTarefa}</Text>
                                                                <Text mb={marginText} color={colorMaoRelizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{re.dsStatus}</Text>
                                                            </Flex>
                                                        </Box>
                                                    }
                                                    {re.idStatusTarefa == 4 &&
                                                        <Box w={'100%'}>
                                                            <Flex px='15px' justify='flex-start' align='flex-end'>
                                                                <Text mb={marginText} color={colorMaoRelizada} fontSize='20px' fontWeight={'bold'} lineHeight='100%'>{re.letraTarefa}</Text>
                                                                <Text mb={marginText} color={colorMaoRelizada} fontSize='15px' fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{`${re.dsStatus}, motivo: (${re.descMotivo})`}</Text>
                                                            </Flex>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Flex>
                                        )
                                    })
                                    :
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Índice de eficiência</Text>
                                }
                            </Box>
                            <Flex px={'0px'} justify='space-around' pb={'10px'} align='center'>
                                <Flex w={'33%'} px={'5px'} justify='center' align='center' >
                                    <Box w={'100%'} minH={'50px'} py={'5px'} bgGradient={gradienGray} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Programado'}</Text>
                                        <Text noOfLines={1} pb={marginText != '0px' ? '5px' : '0px'} mt={'5px'} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{tarefasPendentesAndTarefaDoDia}</Text>
                                    </Box>
                                </Flex>
                                <Flex w={'33%'} px={'5px'} justify='center' align='center' >
                                    <Box w={'100%'} minH={'50px'} py={'5px'} bgGradient={gradienGray} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Atrasadas'}</Text>
                                        <Text mb={marginText} mt={'5px'} color={corStatus} fontSize={'15px'} fontWeight='bold' align={'center'}>{item.atrasadas}</Text>
                                    </Box>
                                </Flex>
                                <Flex w={'33%'} px={'5px'} justify='center' align='center' >
                                    <Box w={'100%'} minH={'50px'} py={'5px'} bgGradient={gradienGray} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Índice de eficiência'}</Text>
                                        <Text mb={marginText} mt={'5px'} color={corStatus} fontSize={'15px'} fontWeight='bold' align={'center'}>{`${item.percentualIndiceDeEficiencia}%`}</Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    )
                })}
            </SimpleGrid>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' mt={'100px'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }} bg={'blackAlpha.100'}>
            <Card direction='column' w='100%' px='0px' py='10px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                {renderAlert()}
                <Flex px='25px' justify='flex-end' mt='2px' align='center'>
                    <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                </Flex>
                <Box ref={header} position={'relative'}>
                    <Flex flexDirection={'column'} justify='center' align='center'>
                        <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'} lineHeight={'100%'}>Status Diário</Text>
                        <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMesCorrente} - ${nomeMunicipio}/${idUf}`}</Text>
                        <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                    </Flex>
                    <Box position={'absolute'} top={'1px'} left={'10px'}>
                        <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                    </Box>
                    <Box mt={'15px'} >
                        <Text mb={marginText} color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Gerencie suas tarefas do dia</Text>
                    </Box>
                    {renderCards()}
                    {arraySeringueiros01.length > 0 && renderSeringueiros(arraySeringueiros01)}
                </Box>
                {arraySeringueiros02.length > 0 &&
                    <Box ref={pdfRef02} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros02)}
                    </Box>
                }
                {arraySeringueiros03.length > 0 &&
                    <Box ref={pdfRef03} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros03)}
                    </Box>
                }
                {arraySeringueiros04.length > 0 &&
                    <Box ref={pdfRef04} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros04)}
                    </Box>
                }
                {arraySeringueiros05.length > 0 &&
                    <Box ref={pdfRef05} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros05)}
                    </Box>
                }
                {arraySeringueiros06.length > 0 &&
                    <Box ref={pdfRef06} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros06)}
                    </Box>
                }
                {arraySeringueiros07.length > 0 &&
                    <Box ref={pdfRef07} w={'100%'}>
                        {renderSeringueiros(arraySeringueiros07)}
                    </Box>
                }
            </Card>
            <LoaderBlack isOpen={loading} subTitulo={'Dependendo da quantidade de registros, esse processo pode levar mais de um minuto'} />
        </Box>
    );
};
