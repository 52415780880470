import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Select,
    SimpleGrid,
    Box,
    Wrap,
} from "@chakra-ui/react";
import { LIST_UFS } from 'model/MockData'
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export function FormProdutor() {

    const history = useHistory();
    const [nomeTela, setNomeTela] = useState('');
    const [isValidNome, setIsValidNome] = useState(false);
    const [inputNome, setInputNome] = useState('');
    const [inputEndereco, setInputEndereco] = useState('');
    const [idEnderecoP, setIdEnderecoP] = useState(null);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [isValidCelular, setIsValidCelular] = useState(false);
    const [inputCelular, setInputCelular] = useState('');
    const [isValidUf, setIsValidUf] = useState(false);
    const [ufSelecionado, setUfSelecionado] = useState('');
    const [isValidMunicipio, setIsValidMunicipio] = useState(false);
    const [inputTelefoneFixo, setInputTelefoneFixo] = useState('');
    const [listMunicipio, setListMunicipio] = useState([]);
    const [municipioSelecionado, setMunicipioSelecionado] = useState('');
    const [isDisabledMunicipio, setIsDisabledMunicipio] = useState(true);
    const [idUsuarioP, setIdUsuarioP] = useState(null);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [operacao, setOperacao] = useState(ActionTypes.OPERACAO_INSERT);

    const [tituloModal, setTituloModal] = useState('');
    const [mensageAlert, setMensageAlert] = useState('');
    
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [isCelularOrCpfJaExiste, setisCelularOrCpfJaExiste] = useState(false);
    
    const textColor = useColorModeValue("secondaryGray.900", "white");

    useEffect(() => {
        async function loaderScreen() {
            const dadosProdutor = localStorage.getItem('@HE-dadosProdutorParaEdicao');
            if (dadosProdutor !== null) {

                setNomeTela('Editar Produtor');
                const param = JSON.parse(dadosProdutor);

                setOperacao(ActionTypes.OPERACAO_UPDATE);
                localStorage.removeItem('@HE-dadosProdutorParaEdicao');

                const idUser = param.idUsuario;
                setIdUsuarioP(idUser);

                setInputNome(param.nome !== null && param.nome !== '' ? param.nome : '');
                setInputTelefoneFixo(param.telefoneFixo !== null && param.telefoneFixo !== '' ? mascaraTelefone(param.telefoneFixo) : '');
                setInputCelular(param.numeroCelular !== null && param.numeroCelular !== '' ? mascaraTelefone(param.numeroCelular) : '');
                setInputEmail(param.emailPessoal !== null && param.emailPessoal !== '' ? param.emailPessoal : '');

                const url = `api/usuario/retornaUsuarioProdutorPorId?idTipoUsuario=1&idUsuario=${idUser}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.length > 0) {

                        const obj = res[0].objEndereco;
                        console.log('');
                        const idMu = obj.idMunicipio !== null && obj.idMunicipio !== '' ? `${obj.idMunicipio}` : null;
                        setMunicipioSelecionado(idMu);
                        setIdEnderecoP(obj.idEndereco);
                        setInputEndereco(obj.endereco !== null && obj.endereco !== '' ? obj.endereco : '');
                        carregarUf(obj.idUf);
                        setTelaCarregada(true);
                    }
                }
            } else {
                setOperacao(ActionTypes.OPERACAO_INSERT);
                setNomeTela('Cadastrar Produtor');
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const changeUf = async (e) => {
        const uf = e.target.value;
        carregarUf(uf);
    }

    // const carregarUf22 = async (e) => {
    //     const uf = e;
    //     setUfSelecionado(`${uf}`)
    //     const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);

    //     if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
    //         const res = await response.respostaRequisicao.data;

    //         if (res.length > 0) {
    //             let arr = res.map((item, index) => {
    //                 return {
    //                     value: `${item.idMunicipio}`,
    //                     label: item.nomeMunicipio,
    //                 }
    //             })
    //             setListMunicipio(arr);
    //             setIsDisabledMunicipio(false);
    //         }
    //     }
    // }

    const carregarUf = async (e) => {
        const uf = e;
        setUfSelecionado(`${uf}`);

        if (uf != '') {
            const response = await Api.getRequest(`api/ua/listaMunicipios?idUf=${uf}`);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                if (res.length > 0) {
                    let arr = res.map((item, index) => {
                        return {
                            value: `${item.idMunicipio}`,
                            label: item.nomeMunicipio,
                        }
                    })
                    setListMunicipio(arr);
                    setIsDisabledMunicipio(false);
                }
            }
        } else {
            setListMunicipio([]);
            setMunicipioSelecionado('');
        }
    }


    const changeMunicipio = async (e) => {
        const muni = e.target.value
        setMunicipioSelecionado(muni);
    }

    const salvarProdutor = async () => {
        try {
            setisCelularOrCpfJaExiste(false);
            let valid = false;

            if (inputNome.length < 3) {
                setIsValidNome(true);
                valid = true;
            } else {
                setIsValidNome(false);
            }

            if (inputEmail.length < 3) {
                setIsValidEmail(true);
                valid = true;
            } else {
                setIsValidEmail(false);
            }

            if (inputCelular.length < 15) {
                setIsValidCelular(true);
                valid = true;
            } else {
                setIsValidCelular(false);
            }

            if (ufSelecionado === null || ufSelecionado === '') {
                setIsValidUf(true);
                valid = true;
            } else {
                setIsValidUf(false);
            }
            if (municipioSelecionado === null || municipioSelecionado === '') {
                setIsValidMunicipio(true);
                valid = true;
            } else {
                setIsValidMunicipio(false);
            }

            if (valid) return;

            const permissoesP = [
                { idPermissao: 1 },
                { idPermissao: 2 },
                { idPermissao: 3 },
                { idPermissao: 4 }
            ]

            const objEnderecoP = {
                idEndereco: idEnderecoP,
                idUf: ufSelecionado,
                idMunicipio: municipioSelecionado,
                endereco: inputEndereco !== '' ? inputEndereco : null,
            }

            const fixoSemMascara = retiraMascaraMaterNumeros(inputTelefoneFixo);
            const celularSemMascara = retiraMascaraMaterNumeros(inputCelular);
            const emailP = `sangrador${celularSemMascara}@hevea.com`;

            const jsonUsuario = {
                idUsuario: idUsuarioP,
                nome: inputNome,
                email: emailP,
                numeroCelular: celularSemMascara,
                senha: '123456',
                emailPessoal: inputEmail,
                telefoneFixo: fixoSemMascara,
                idTipoUsuario: ActionTypes.TIPO_USUARIO_PRODUTOR,
                permissoes: permissoesP,
                redefinirSenha: true,
                objEndereco: objEnderecoP,
            }

            if (operacao === 'update') {
                const response = await Api.putRequestSemAccessToken(`api/usuario/alterarUsuario`, jsonUsuario);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.numeroCelularJaExiste == true) {
                        setisCelularOrCpfJaExiste(true);
                        setTituloModal('Ops!');
                        setMensageAlert(`Número de celular já existe, cadastrado por: ${res.nome}.`);
                        setOpenModalConfimacao(true);
                        setIsValidCelular(true);
                    } else {
                        setTituloModal('Ok');
                        setMensageAlert('Processo Realizado com Sucesso!');
                        setOpenModalConfimacao(true);
                        setIsValidCelular(false);
                    }
                } else {

                }

            } else {
                const response = await Api.postRequestSemAccessToken(`api/usuario/cadastrarUsuario`, jsonUsuario);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    if (res.numeroCelularJaExiste == true) {
                        setisCelularOrCpfJaExiste(true);
                        setTituloModal('Ops!');
                        setMensageAlert(`Número de celular já existe, cadastrado por: ${res.nome}.`);
                        setOpenModalConfimacao(true);
                        setIsValidCelular(true);
                    } else {
                        setTituloModal('Ok');
                        setMensageAlert('Processo Realizado com Sucesso!');
                        setOpenModalConfimacao(true);
                        setIsValidCelular(false);
                    }
                } else {

                }
            }

        } catch (error) {
            console.log('Erro na classe FormProdutores metodo salvarProdutor', error);
        }
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputCelular(telComMascara);
    }

    const changeTelefonefixo = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputTelefoneFixo(telComMascara);
    }

    const closeAndGoBack = () => {
        setOpenModalConfimacao(false);

        if (operacao != ActionTypes.OPERACAO_UPDATE) {
            if(!isCelularOrCpfJaExiste){
                history.goBack();
            }
        }
    }

    function renderInputs() {
        return (
            <Card py='20px' px='20px' borderWidth={3} >
                <Box w={'100%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box>
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Nome *</Text>
                            <Input
                                value={inputNome}
                                onChange={(e) => setInputNome(e.target.value)}
                                isInvalid={isValidNome}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box>
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Endereço</Text>
                            <Input
                                value={inputEndereco}
                                onChange={(e) => setInputEndereco(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box w={'100%'} >
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                        <Box>
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>E-mail *</Text>
                            <Input
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                                isInvalid={isValidEmail}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder=""
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="email"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                            <Box >
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Celular *</Text>
                                <Input
                                    value={inputCelular}
                                    onChange={changeCelular}
                                    isInvalid={isValidCelular}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                            <Box>
                                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Telefone Fixo</Text>
                                <Input
                                    value={inputTelefoneFixo}
                                    onChange={changeTelefonefixo}
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    type="text"
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>
                <Box w={'100%'}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='0px'>
                        <Box>
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>UF *</Text>
                            <Select
                                value={ufSelecionado}
                                isInvalid={isValidUf}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeUf}
                            >
                                {LIST_UFS.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box >
                            <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Municipio *</Text>
                            <Select
                                value={municipioSelecionado}
                                isInvalid={isValidMunicipio}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onChange={changeMunicipio}
                                disabled={isDisabledMunicipio}
                            >
                                {listMunicipio.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>
                </Box>
            </Card>
        )
    }

    function renderMenssage() {
        return (
            <Flex flexDirection={'column'} px='2px' mt='20px' justify='center' align='center'>
                <Text color={'orange.800'} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'OBS: O usuário será criado com uma senha padrão: 123456'}</Text>
                <Text color={'orange.800'} fontSize='15px' fontWeight={'light'} align={'center'}>{'Após logado o usuário pode acessar seu perfil para alteração'}</Text>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>{nomeTela}</Text>
            </Flex>
            {renderInputs()}
            {renderMenssage()}
            <Wrap spacing='30px' mx={'10px'} mt={'20px'} justify='space-between'>
                <Button variant="brand" onClick={()=> { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={salvarProdutor}>Salvar</Button>
            </Wrap>
            
            <ModalConfirmacao isOpen={openModalConfimacao} descricao={mensageAlert} titulo={tituloModal} onPress={()=> { closeAndGoBack() }} />
        </Card>
    );
}

export default FormProdutor;
