import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Flex,
} from "@chakra-ui/react";

function ModalDialog({ isOpen, onPressOk, onPressCancel, titulo = 'OBS', descricao = 'Deseja Realmente desativar esse seringueiro?' }) {

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={'red'} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{titulo}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{descricao}</Text>
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="miniBrand" onClick={onPressOk}>Sim</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialog;
